import { ReactNode, useEffect } from 'react'

import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'
import { useQuery } from 'react-query'

import {
  Box,
  Flex,
  Link,
  Typography,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { CardSac } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import { CardSacType } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto/CardSac/interfaces'

import { Carousel, NewsCard, ParceriaPremiadaBanner } from '@/components'
import { useCurrentOrganization, useOrganizations } from '@/modules'
import { api } from '@/services'
import { useGTM } from '@/tools'
import { useRemoteConfig } from '@/tools/hooks/firebase'
import {
  isCapitalizacaoEnabled,
  isEssencialEnabled,
  isImobiliariaEnabled,
} from '@/tools/partnership'
import { HomeBanner } from './components'

dayjs.locale('pt-br')

const now: dayjs.Dayjs = dayjs().startOf('day')

const today: string = `
  Hoje é ${now.format('dddd').toLowerCase()},
  ${now.format('DD')} de ${now.format('MMMM').toLocaleLowerCase()}
`

const Content = ({ children, sx = {} }: { children: ReactNode; sx?: object }) => (
  <Box
    sx={{
      display: 'grid',
      gap: '1rem',
      gridTemplateColumns: {
        xs: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)',
        md: 'repeat(2, 1fr)',
        lg: 'repeat(4, 1fr)',
      },
      ...sx,
    }}
  >
    {children}
  </Box>
)

const Card = ({ children }: { children: ReactNode }) => (
  <Flex
    sx={{
      flexDirection: 'column',
      padding: '1rem',
      borderRadius: '.5rem',
      backgroundColor: 'var(--porto-primitive-black-05)',
    }}
  >
    {children}
  </Flex>
)

const Dashboard = () => {
  const { setCustomData, setDataLayer } = useGTM()
  const { values } = useRemoteConfig()
  const profile = useCurrentOrganization()
  const isBroker = profile.type === 'BROKER'
  const newsCardsData = values.newsCards

  // useSoluCXWidget()

  const {
    store: { active: currentProfile },
  } = useOrganizations()

  const { data } = useQuery(
    ['statistics', currentProfile],
    async () => await api.contracts.getStatistics(currentProfile),
  )

  useEffect(() => {
    setCustomData({
      site: {
        brand: 'portoseguro',
        portal: 'imobiliarias',
      },
      page: {
        name: 'Portal Imobiliária - Painel de Acompanhamento',
      },
    })

    setDataLayer({
      event: 'step_change',
      titulo: 'Portal Imobiliária - Painel de Acompanhamento',
      etapa: '/area-logada/painel-de-acompanhamento',
    })

    //eslint-disable-next-line
  }, [])

  const cards: Array<{ label: string; value: string; link: string }> = [
    {
      label: 'Seguro Incêndio',
      value: !!data ? String(data.budgets.PORTO_IMOBILIARIA) : String(0),
      link: '/consultas/incendio',
    },
    {
      label: 'Fiança Locatícia',
      value: !!data ? String(data.budgets.PORTO_ALUGUEL) : String(0),
      link: '/consultas/fianca',
    },
    {
      label: 'Fiança Locatícia Essencial',
      value: !!data ? String(data.budgets.PORTO_ESSENCIAL) : String(0),
      link: '/consultas/essencial',
    },
    {
      label: 'Título de Capitalização',
      value: !!data ? String(data.budgets.PORTO_CAPITALIZACAO) : String(0),
      link: '/consultas/capitalizacao',
    },
  ]

  const products: Array<CardSacType & { testKey }> = [
    {
      icon: 'fireflame',
      upperTitle: 'Seguro obrigatório',
      title: 'Seguro Incêndio',
      testKey: 'new-proposal-fire',
      actions: [
        {
          to: '/contratos/novo/imobiliaria',
          label: 'Nova proposta',
          disabled: !isImobiliariaEnabled(currentProfile),
        },
        {
          to: '/produtos/seguro-incendio',
          label: 'Entenda o produto',
        },
      ],
    },
    {
      icon: 'receiptcheck',
      upperTitle: 'Garantia de aluguel',
      title: 'Fiança Locatícia',
      testKey: 'new-proposal-financa',
      actions: [
        {
          to: '/contratos/novo/essencial',
          label: 'Nova proposta',
          disabled: !isEssencialEnabled(currentProfile),
        },
        {
          to: '/produtos/fianca-locaticia',
          label: 'Entenda o produto',
        },
      ],
    },
    {
      icon: 'ticket',
      upperTitle: 'Garantia de aluguel',
      title: 'Título de Capitalização',
      testKey: 'new-proposal-capitalization',
      actions: [
        {
          to: '/contratos/novo/capitalizacao',
          label: 'Nova proposta',
          disabled: !isCapitalizacaoEnabled(currentProfile),
        },
        {
          to: '/produtos/titulo-de-capitalizacao',
          label: 'Entenda o produto',
        },
      ],
    },
  ]

  const renderNewsCards = () =>
    newsCardsData
      .filter(data => !isBroker || !data.isRealEstateOnly)
      .map((cardData, index) => <NewsCard key={index} {...cardData} />)

  return (
    <>
      <Flex
        sx={{
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <Box
          sx={{
            paddingX: { xs: '1rem', md: '2rem', lg: '4rem' },
            paddingBottom: '1.5rem',
            backgroundColor: 'porto-primitive-white',
          }}
        >
          <Typography
            as="span"
            variant="porto-title-3-bold"
            color="porto-primitive-black-85"
            content={today}
            data-test-id="current-date"
          />
        </Box>
        <Content
          sx={{
            backgroundColor: 'system-primitive-off-white',
            padding: {
              xs: '1.5rem 1rem',
              md: '1.75rem 2rem',
              lg: '1.75rem 4rem',
            },
            gridTemplateColumns: {
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(1, 1fr)',
              md: 'repeat(2, 1fr)',
              lg: 'repeat(3, 1fr)',
            },
          }}
        >
          {products.map(({ icon, upperTitle, title, actions, testKey }, index) => (
            <CardSac
              key={index}
              icon={icon}
              upperTitle={upperTitle}
              title={title}
              actions={actions}
              sx={{
                height: '100%',
              }}
              data-test-id={testKey}
            />
          ))}
        </Content>

        {!!process.env.REACT_APP_ENABLE_PARCERIA_PREMIADA && (
          <Box
            sx={{
              backgroundColor: 'porto-primitive-white',
              padding: {
                xs: '1.5rem 1rem',
                md: '1.75rem 2rem',
                lg: '1.75rem 4rem',
              },
            }}
          >
            <ParceriaPremiadaBanner />
          </Box>
        )}

        <HomeBanner imageUrl="/images/5fecfa8b3a444925527c5d658a2cb253.jfif">
          <Typography as="h3" variant="porto-title-3-bold" color="#25213f">
            Nova trilha de aprendizado do Portal das Imobiliárias
          </Typography>
          <br />
          <br />
          <Typography as="p" variant="porto-text-body-2-regular" color="#0A0047">
            A partir de novembro, fique por dentro dos nossos produtos e aprenda técnicas de vendas
            que irão impulsionar seu negócio! Enviaremos as informações por e-mail.
            <br />
            <br />
            Caso queira sugerir temas,{' '}
            <strong>
              <a
                href="https://forms.gle/toqXnZUcLUZrV7Yj9"
                target="_blank"
                rel="noopener noreferrer"
              >
                acesse o formulário
              </a>
            </strong>
            .
          </Typography>
        </HomeBanner>

        <Box
          sx={{
            backgroundColor: 'porto-primitive-white',
            padding: {
              xs: '1.5rem 1rem',
              md: '1.75rem 2rem',
              lg: '1.75rem 4rem',
            },
          }}
        >
          <Typography
            variant="porto-title-5-semibold"
            color="system-primitive-neutral-900"
            content="Propostas no mês"
            sx={{
              marginBottom: '1rem',
            }}
          />

          <Content>
            {cards.map(({ label, value, link }, index) => (
              <Card key={index}>
                <Typography
                  variant="porto-title-2-bold"
                  color="porto-banking-primary"
                  content={value}
                />
                <Typography
                  variant="porto-text-body-2-bold"
                  color="system-primitive-neutral-900"
                  content={label}
                />
                <Box
                  sx={{
                    marginTop: '.75rem',
                  }}
                >
                  <Link to={link}>
                    <Typography
                      variant="porto-text-link-caption-bold"
                      color="system-primitive-blue-500"
                      content="Ver propostas"
                    />
                  </Link>
                </Box>
              </Card>
            ))}
          </Content>
        </Box>
        <Box
          sx={{
            padding: {
              xs: '1.5rem 1rem 4rem',
              md: '1.75rem 2rem',
              lg: '1.75rem 4rem',
            },
          }}
        >
          <Typography
            variant="porto-title-5-semibold"
            color="system-primitive-neutral-900"
            content="Fique por dentro"
            sx={{ marginBottom: '1rem' }}
          />
          <Carousel
            settings={{
              slidesToShow: 3,
              dots: true,
              infinite: false,
              slidesToScroll: 1,
              responsive: [
                {
                  breakpoint: 768,
                  settings: {
                    arrows: false,
                    slidesToShow: 2,
                  },
                },
                {
                  breakpoint: 600,
                  settings: {
                    arrows: false,
                    slidesToShow: 1,
                  },
                },
              ],
            }}
          >
            {renderNewsCards()}
          </Carousel>
        </Box>
      </Flex>
    </>
  )
}

export default Dashboard
