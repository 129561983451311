import { useState } from 'react'
import { isBrowser, isMobile } from 'react-device-detect'

import { Conditional } from '@/components'
import { useContract, useOrganizations } from '@/modules'
import { formatDate } from '@/tools'

import { PortoImobiliaria } from '../../constants'

import { formatToCPFOrCNPJ, formatToPhone } from 'brazilian-values'
import {
  Container,
  Group,
  Head,
  Icon,
  Item,
  Label,
  Panel,
  Title,
  Toggle,
  Value,
} from './styles'

const Resume = () => {
  const contracts = useContract()
  const organization = useOrganizations()

  const contract = contracts.payload.contract
  const customer = contracts.payload.customer
  const estate = contracts.payload.estate
  const address = estate.address

  const [expanded, setExpanded] = useState(isBrowser ? true : false)

  function commissionParse(percentage) {
    return (
      {
        '1': '1%',
        '2': '2%',
        '3': '3%',
        '4': '4%',
        '5': '5%',
        '6': '6%',
        '7': '7%',
        '8': '8%',
        '9': '9%',
        '10': '10%',
        '11': '11%',
        '12': '12%',
        '13': '13%',
        '14': '14%',
        '15': '15%',
        '16': '16%',
        '17': '17%',
        '18': '18%',
        '19': '19%',
        '20': '20%',
        '154': '21%',
        '155': '22%',
        '156': '23%',
        '157': '24%',
        '158': '25%',
        '159': '26%',
        '160': '27%',
        '161': '28%',
        '162': '29%',
        '163': '30%',
        '164': '31%',
        '165': '32%',
        '166': '33%',
        '167': '34%',
        '168': '35%',
        '169': '36%',
        '170': '37%',
        '171': '38%',
        '172': '39%',
        '173': '40%',
        '174': '41%',
        '175': '42%',
        '176': '43%',
        '177': '44%',
        '178': '45%',
        '179': '46%',
        '180': '47%',
        '181': '48%',
        '182': '49%',
        '183': '50%',
        '184': '51%',
        '185': '52%',
        '186': '53%',
        '187': '54%',
        '188': '55%',
        '189': '56%',
        '190': '57%',
        '191': '58%',
        '192': '59%',
        '193': '60%',
        '194': '61%',
        '195': '62%',
        '196': '63%',
        '197': '64%',
        '198': '65%',
        '199': '66%',
        '200': '67%',
        '201': '68%',
        '202': '69%',
        '203': '70%',
      }[percentage] || percentage
    )
  }

  const toggleExpandable = () => {
    setExpanded((state) => !state)
  }

  return (
    <Container>
      {isMobile && (
        <Toggle.Box expanded={expanded} onClick={toggleExpandable}>
          <Toggle.Action>
            {expanded
              ? 'Esconder resumo'
              : 'Clique aqui para visualizar resumo do orçamento'}

            <Icon name={expanded ? 'chevron-up' : 'chevron-down'} />
          </Toggle.Action>
        </Toggle.Box>
      )}

      {expanded && (
        <Panel>
          <Head>
            Resumo <Icon name="message-square" />
          </Head>

          <Group>
            <Title>
              <Icon name="clipboard" /> Dados básicos
            </Title>

            <Item>
              <Label>Cliente</Label>
              <Value data-suppress="true" space={true}>{customer.socialName || customer.name}</Value>
            </Item>

            <Item>
              <Label>{customer.document.length < 14 ? 'CPF' : 'CNPJ'}</Label>
              <Value data-suppress="true">{formatToCPFOrCNPJ(customer.document)}</Value>
            </Item>

            <Item>
              <Label>Contato</Label>
              <Value data-suppress="true">{formatToPhone(customer.phone)}</Value>
            </Item>
          </Group>

          <Group>
            <Title>
              <Icon name="map-pin" /> Endereço do Imóvel/Local de risco
            </Title>

            <Item>
              <Value space={true} data-suppress="true">
                {address.street}, nº {address.number} {address.complement}
                <br />
                {address.neighborhood}, {address.city} - {address.state}
              </Value>
            </Item>
          </Group>

          <Group>
            <Title>
              <Icon name="file-text" />
              Dados do Contrato
            </Title>
            <Item>
              <Label>Plano</Label>
              <Value>{PortoImobiliaria.Plan[contract.plan]}</Value>
            </Item>

            <Conditional when={organization.store.active.type === 'BROKER'}>
              <Item>
                <Label>Comissão</Label>
                <Value>{commissionParse(contract.operation_code)}</Value>
              </Item>
            </Conditional>

            <Item>
              <Label>Cláusula de Serviço</Label>
              <Value>
                {PortoImobiliaria.ServiceClause[contract.service_clause]}
              </Value>
            </Item>

            <Conditional when={!!contract.prolabore}>
              <Item>
                <Label>Pro-labore</Label>
                <Value>
                  {contract.prolabore}%
                </Value>
              </Item>
            </Conditional>
          </Group>

          <Group>
            <Title>
              <Icon name="calendar" />
              Vigência
            </Title>
            <Item>
              <Label>Início em</Label>
              <Value>
                {formatDate(contract.period.start, { timeZone: 'UTC' })}
              </Value>
            </Item>

            <Item>
              <Label>Fim em</Label>
              <Value>
                {formatDate(contract.period.end, { timeZone: 'UTC' })}
              </Value>
            </Item>
          </Group>
        </Panel>
      )}
    </Container>
  )
}

export default Resume
